var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.entity)?_c('v-expansion-panel',[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.operationsCount != null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"align-self-center",attrs:{"color":"success","small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.operationsCount)+" ")])]}}],null,false,1645588188)},[_c('span',[_vm._v("Operation Count")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.entity.active ? 'success' : 'error'}},[_vm._v(" mdi-circle ")])],1)]}}],null,false,834512897)},[_c('span',[_vm._v(_vm._s(_vm.entity.active ? "Active" : "Inactive"))])]),(!_vm.noNavigate)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"no-rotate",attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.goToOverview.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"no-rotate",attrs:{"color":"primary"}},[_vm._v(" mdi-location-enter ")])],1)]}}],null,false,1712972421)},[_c('span',[_vm._v(_vm._s(_vm.title)+" Overview")])]):_vm._e(),(!_vm.noNavigate)?_c('v-icon',[_vm._v("mdi-chevron-down")]):_vm._e()]},proxy:true}],null,false,2745459637)},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(_vm._s(_vm.title))])],1)],1),_c('v-expansion-panel-content',[(_vm.entity)?_c('InlineFieldsForm',{attrs:{"entity":_vm.entity,"entityStore":_vm.processTemplatesStore,"fields":[
        'title',
        'active',
        'hide_on_flow_ticket',
        'hide_detail',
        'pre_operation' ],"canEdit":_vm.canEdit,"noBadge":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var childProps = ref.childProps;
      var childEvents = ref.childEvents;
return [_c('v-container',[_c('v-row',[_c('v-col',_vm._b({staticClass:"pt-2",attrs:{"sm":"12"}},'v-col',_vm.columnBindings,false),[_c('InlineEdit',_vm._g(_vm._b({attrs:{"label":"Title","rules":_vm.rules.title,"outlined":"","autofocus":!!_vm.isNew}},'InlineEdit',childProps.title,false),childEvents.title))],1),_c('v-col',_vm._b({},'v-col',_vm.columnBindings,false),[_c('InlineEdit',_vm._g(_vm._b({attrs:{"label":"Active","inputType":"checkbox","hide-details":"","outlined":"","rules":_vm.rules.active,"canEdit":_vm.activeEnabled}},'InlineEdit',childProps.active,false),childEvents.active))],1),_c('v-col',_vm._b({},'v-col',_vm.columnBindings,false),[_c('InlineEdit',_vm._g(_vm._b({attrs:{"label":"Hide On Flow Ticket","inputType":"checkbox","hide-details":"","outlined":"","canEdit":_vm.canEdit && !_vm.isNew}},'InlineEdit',childProps.hide_on_flow_ticket,false),childEvents.hide_on_flow_ticket))],1),_c('v-col',_vm._b({},'v-col',_vm.columnBindings,false),[_c('InlineEdit',_vm._g(_vm._b({attrs:{"label":"Pre Operation","inputType":"checkbox","outlined":"","canEdit":_vm.canEdit && !_vm.isNew}},'InlineEdit',childProps.pre_operation,false),childEvents.pre_operation))],1)],1)],1)]}}],null,false,2153168346)}):_vm._e(),(!_vm.noDelete && _vm.isNew)?_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(_vm.entity)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"no-rotate",attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1)]}}],null,false,1025500991)},[_c('span',[_vm._v("Delete Process")])])],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }