<template>
  <v-expansion-panel v-if="entity">
    <v-expansion-panel-header>
      <template v-slot:actions>
        <v-tooltip v-if="operationsCount != null" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              color="success"
              class="align-self-center"
              small
            >
              {{ operationsCount }}
            </v-chip>
          </template>
          <span>Operation Count</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon :color="entity.active ? 'success' : 'error'">
                mdi-circle
              </v-icon>
            </v-btn>
          </template>
          <span>{{ entity.active ? "Active" : "Inactive" }}</span>
        </v-tooltip>
        <v-tooltip v-if="!noNavigate" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              class="no-rotate"
              @click.prevent.stop="goToOverview"
            >
              <v-icon color="primary" class="no-rotate">
                mdi-location-enter
              </v-icon>
            </v-btn>
          </template>
          <span>{{ title }} Overview</span>
        </v-tooltip>
        <v-icon v-if="!noNavigate">mdi-chevron-down</v-icon>
      </template>
      <v-row no-gutters>
        <v-col cols="12">{{ title }}</v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <InlineFieldsForm
        v-if="entity"
        :entity="entity"
        :entityStore="processTemplatesStore"
        :fields="[
          'title',
          'active',
          'hide_on_flow_ticket',
          'hide_detail',
          'pre_operation',
        ]"
        :canEdit="canEdit"
        noBadge
      >
        <template v-slot:default="{ childProps, childEvents }">
          <v-container>
            <v-row>
              <v-col v-bind="columnBindings" sm="12" class="pt-2">
                <InlineEdit
                  v-bind="childProps.title"
                  v-on="childEvents.title"
                  label="Title"
                  :rules="rules.title"
                  outlined
                  :autofocus="!!isNew"
                />
              </v-col>
              <v-col v-bind="columnBindings">
                <InlineEdit
                  v-bind="childProps.active"
                  v-on="childEvents.active"
                  label="Active"
                  inputType="checkbox"
                  hide-details
                  outlined
                  :rules="rules.active"
                  :canEdit="activeEnabled"
                />
              </v-col>
              <v-col v-bind="columnBindings">
                <InlineEdit
                  v-bind="childProps.hide_on_flow_ticket"
                  v-on="childEvents.hide_on_flow_ticket"
                  label="Hide On Flow Ticket"
                  inputType="checkbox"
                  hide-details
                  outlined
                  :canEdit="canEdit && !isNew"
                />
              </v-col>
              <v-col v-bind="columnBindings">
                <InlineEdit
                  v-bind="childProps.pre_operation"
                  v-on="childEvents.pre_operation"
                  label="Pre Operation"
                  inputType="checkbox"
                  outlined
                  :canEdit="canEdit && !isNew"
                />
              </v-col>
            </v-row>
          </v-container>
        </template>
      </InlineFieldsForm>
      <v-card-actions v-if="!noDelete && isNew">
        <v-spacer />
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="deleteItem(entity)">
              <v-icon color="red" class="no-rotate">mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Delete Process</span>
        </v-tooltip>
      </v-card-actions>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { computed, ref, toRef, watch } from "vue-demi";
import { useFind } from "feathers-pinia";
import { useProcessTemplates } from "../store/processTemplates.pinia";
import { useWorkcenters } from "../store/workcenters.pinia";
import { useProcessCount } from "../store/processCount.pinia";
import InlineFieldsForm from "./InlineFieldsForm.vue";
import InlineEdit from "./InlineEdit.vue";
import { required } from "../utils/rules";

export default {
  name: "WorkcenterProcessesCardItem",
  components: {
    InlineFieldsForm,
    InlineEdit,
  },
  props: {
    entity: {
      type: Object,
      default: () => ({}),
    },
    open: {
      type: Boolean,
      default: false,
    },
    noDelete: {
      type: Boolean,
      default: false,
    },
    noNavigate: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { root }) {
    const processTemplatesStore = useProcessTemplates();
    const workcentersStore = useWorkcenters();
    const ProcessCountStore = useProcessCount();
    const workcenterId = toRef(props.entity, "workcenter_id");
    const createdUserId = ref(null);
    const modifiedUserId = ref(null);
    const columnBindings = ref({
      cols: 12,
      sm: 6,
      class: "pb-0 pt-0",
    });

    const title = computed(() => {
      return props.entity?.title?.length
        ? props.entity?.title
        : "New Process (Unsaved)";
    });

    const isNew = computed(() => {
      return props.entity?.__tempId?.length ?? false;
    });

    const operationsCount = computed(() => {
      if (!props.entity?.id) return null;
      const count = parseInt(
        ProcessCountStore.processOperationCountById(props.entity?.id)
      );
      if (isNaN(count)) return null;
      return count;
    });

    const hasActiveOperations = computed(() => {
      const count = operationsCount.value;
      if (count === null) return false;
      return count > 0;
    });

    const siblingParams = computed(() => ({
      query: {
        workcenter_id: workcenterId.value,
      },
    }));

    const { items: siblings } = useFind({
      model: processTemplatesStore.Model,
      params: siblingParams,
    });

    const workcenterIsActive = computed(() => {
      return workcentersStore.itemsById[workcenterId.value]?.active ?? false;
    });

    const activeEnabled = computed(() => {
      // If the workcenter and the entity are both disabled, disable this field (must activate workcenter first)
      if (!workcenterIsActive.value && props.entity?.active == false)
        return false;
      return props.canEdit && !isNew.value && !hasActiveOperations.value;
    });

    const rules = computed(() => {
      return {
        title: [
          required,
          (v) =>
            siblings?.value?.find(
              ({ id, title }) =>
                id !== props.entity.id && title?.trim() === v?.trim()
            ) === undefined || "Title must be unique",
        ],
        active: [
          (v) => {
            if (workcenterIsActive.value == false && v) return false;
            return true;
          },
        ],
      };
    });

    const deleteItem = (item) => {
      if (!item.id && item.__tempId) {
        // This doesn't exist in the database yet, so we can just remove it
        return processTemplatesStore.removeFromStore(item);
      }
      // TODO: UPDATE. make inactive?
      return processTemplatesStore.removeFromStore(item);
    };

    const goToOverview = () => {
      root.$router.push({
        name: "ProcessOverview",
        params: {
          workcenterId: workcenterId.value,
          processId: props.entity.id,
        },
      });
    };

    watch(
      () => props.entity,
      (entity) => {
        if (!entity) return;
        createdUserId.value = entity?.created_by_id;
        modifiedUserId.value = entity?.modified_by_id;
      },
      { immediate: true }
    );

    return {
      operationsCount,
      processTemplatesStore,
      columnBindings,
      title,
      rules,
      isNew,
      hasActiveOperations,
      activeEnabled,
      deleteItem,
      goToOverview,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .no-rotate {
  transform: none !important;
}
</style>
