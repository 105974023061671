<template>
  <v-container>
    <v-row>
      <v-col>
        <v-expansion-panels v-model="panels" multiple accordion readonly>
          <WorkcenterProcessesCardItem
            v-if="item"
            :entity="item"
            :open="true"
            :canEdit="appStore.canEditCurrentFacility"
            noDelete
            noNavigate
            showAuditLog
          />
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <ProcessDetailsList
          v-if="item"
          :processId="processId"
          :canEdit="appStore.canEditCurrentProcess"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, toRef } from "vue-demi";
import { useGet } from "feathers-pinia";
import { useStore } from "../store/app.pinia";
import { useProcessTemplates } from "../store/processTemplates.pinia";
import WorkcenterProcessesCardItem from "./WorkcenterProcessesCardItem.vue";
import ProcessDetailsList from "./ProcessDetailsList.vue";

export default {
  name: "ProcessOverview",
  components: {
    WorkcenterProcessesCardItem,
    ProcessDetailsList,
  },
  props: {
    processId: {
      type: String,
      default: () => null,
    },
  },
  setup(props) {
    const appStore = useStore();
    const processTemplatesStore = useProcessTemplates();
    const processId = toRef(props, "processId");
    const panels = ref([0]); // Open by default

    const { item } = useGet({
      model: processTemplatesStore.Model,
      id: processId,
    });

    return {
      item,
      panels,
      appStore,
    };
  },
};
</script>
