<template>
  <v-card outlined class="mt-5">
    <v-container>
      <v-row>
        <v-col cols="12" sm="6">
          <v-checkbox
            label="Required"
            v-model="currentValue.required"
            :disabled="!canEdit"
          />
          <v-checkbox
            label="Hidden"
            v-model="currentValue.hidden"
            :disabled="!canEdit"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-scroll-x-transition>
            <v-text-field
              v-if="fieldType === 'text'"
              label="Minimum Length"
              v-model="currentValue.minimum_length"
              placeholder="0"
              type="number"
              max="255"
              :rules="rules.minimum_length"
              :disabled="!canEdit"
            ></v-text-field>
          </v-scroll-x-transition>
          <v-scroll-x-transition>
            <v-text-field
              v-if="fieldType === 'text'"
              label="Maximum Length"
              v-model="currentValue.maximum_length"
              placeholder="255"
              type="number"
              max="255"
              :rules="rules.maximum_length"
              :disabled="!canEdit"
            ></v-text-field>
          </v-scroll-x-transition>
          <v-scroll-x-transition>
            <v-text-field
              v-if="fieldType === 'number'"
              label="Minimum Value"
              v-model="currentValue.minimum_value"
              placeholder="255"
              type="number"
              :rules="rules.minimum_value"
              :disabled="!canEdit"
            ></v-text-field>
          </v-scroll-x-transition>
          <v-scroll-x-transition>
            <v-text-field
              v-if="fieldType === 'number'"
              label="Maximum Value"
              v-model="currentValue.maximum_value"
              placeholder="255"
              type="number"
              :rules="rules.maximum_value"
              :disabled="!canEdit"
            ></v-text-field>
          </v-scroll-x-transition>
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-row v-show="canEdit && hasUpdates">
          <v-col class="text-right">
            <v-btn color="red" class="mr-5 mb-3" @click="reset">Cancel</v-btn>
            <v-btn
              color="primary"
              class="mb-3"
              :disabled="!formValid"
              @click="save"
              >Save Validations</v-btn
            >
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-container>
  </v-card>
</template>

<script>
import { isEqual } from "lodash-es";
import { computed, nextTick, ref, watch } from "vue-demi";
import { generateRandomId } from "../utils";
import { minNum, maxNum } from "../utils/rules";
import { useProcessTemplateFieldTypes } from "../store/processTemplateFieldTypes.pinia";

const isNullOrCb = (val, func) => {
  // Allow nullish values
  if (val === null || val === "") return true;
  if (Number.isNaN(parseFloat(val))) return true;
  return func(val);
};

const isNullOrCbRule = (cb) => {
  return (val) => isNullOrCb(val, cb);
};

export default {
  name: "InlineEdit",
  props: {
    value: {
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    canEdit: {
      type: Boolean,
      default: () => false,
    },
    formValid: {
      type: Boolean,
      default: () => false,
    },
    fieldTypeId: {
      type: String,
      default: () => "",
    },
    triggerValidation: {
      type: Function,
      default: () => () => {},
    },
  },
  components: {
    // ActionsConfirmCancel,
  },
  setup(props, ctx) {
    const processTemplateFieldTypesStore = useProcessTemplateFieldTypes();
    // Create an ID for this field
    const id = ref(generateRandomId());
    // Set values
    const initialValue = ref(props.value);
    const currentValue = ref({ ...props.value });
    const hasError = ref(false);

    const fieldType = computed(() => {
      return (
        processTemplateFieldTypesStore.itemsById[props.fieldTypeId]?.title ??
        "text"
      );
    });

    const blur = () => {
      if (ctx.refs[id.value]?.blur) ctx.refs[id.value].blur();
    };

    // Create methods
    const setError = (val) => {
      hasError.value = val;
    };

    const reset = () => {
      blur();
      currentValue.value = { ...initialValue.value };
    };
    const save = () => {
      blur();
      // Validate all fields before setting the value
      if (ctx.refs[id.value]?.form?.validate() === false) return;
      ctx.emit("input", currentValue.value);
    };
    const updateValue = (value) => ctx.emit("input", value);

    // Watch for value changes
    watch(
      () => props.value,
      (newVal) => {
        if (newVal != initialValue.value) {
          // Update the initial value
          initialValue.value = { ...newVal };
          // For now, we'll just update the current value as well
          // TODO: verify what happens for multiple editing at the same time
          currentValue.value = { ...newVal };
        }
      },
      { immediate: true }
    );

    // Trigger validation check for all fields on any change
    watch(
      () => currentValue.value,
      () => {
        nextTick(() => props.triggerValidation());
      },
      { deep: true }
    );

    const hasUpdates = computed(
      () => !isEqual(initialValue.value, currentValue.value)
    );

    const rules = computed(() => {
      // minimum_value can't be more than maximum_value
      const minValMax = currentValue.value.maximum_value ?? Infinity;
      // maximum_value can't be less than minimum_value
      const maxValMin =
        parseFloat(currentValue.value.maximum_value) <
        parseFloat(currentValue.value.minimum_value)
          ? currentValue.value.minimum_value
          : -Infinity;
      const minLengthMax =
        parseFloat(currentValue.value.maximum_length) < 255
          ? parseFloat(currentValue.value.maximum_length)
          : 255;
      const maxLengthMin =
        parseFloat(currentValue.value.minimum_length) > 0
          ? currentValue.value.minimum_length
          : 0;
      return {
        minimum_length: [
          isNullOrCbRule(minNum(0)),
          isNullOrCbRule(maxNum(minLengthMax)),
        ],
        maximum_length: [
          isNullOrCbRule(minNum(maxLengthMin)),
          isNullOrCbRule(maxNum(255)),
        ],
        minimum_value: [
          isNullOrCbRule(minNum(-Infinity)),
          isNullOrCbRule(maxNum(minValMax)),
        ],
        maximum_value: [isNullOrCbRule(minNum(maxValMin))],
      };
    });

    return {
      id,
      fieldType,
      hasError,
      initialValue,
      currentValue,
      reset,
      save,
      updateValue,
      setError,
      hasUpdates,
      rules,
    };
  },
};
</script>

<style lang="scss" scoped>
$text-color-here: #000;
::v-deep.theme--light {
  &.v-label.v-label--is-disabled,
  &.v-input--is-disabled,
  &.v-input--is-disabled input,
  &.v-input--is-disabled textarea,
  .inline--label {
    color: $text-color-here;
  }
}
</style>
