var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.operationDetailsCount != null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"align-self-center mr-1",attrs:{"color":"success","small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.operationDetailsCount)+" ")])]}}],null,false,57951022)},[_c('span',[_vm._v("Operation Details Count")])]):_vm._e(),_c('v-icon',[_vm._v("mdi-chevron-down")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.entity.active ? 'success' : 'error',"small":""}},[_vm._v(" mdi-circle ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.entity.active ? "Active" : "Inactive"))])])]},proxy:true}])},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center"},[(_vm.entity && _vm.entity.active && _vm.canEdit)?_c('v-icon',{staticClass:"drag-handle mr-2",attrs:{"color":"grey"}},[_vm._v(" mdi-cursor-move ")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.titleText)+" ")])],1)],1)],1),_c('v-expansion-panel-content',[(_vm.entity)?_c('InlineFieldsForm',{attrs:{"entity":_vm.entity,"entityStore":_vm.processTemplateFieldsStore,"fields":[
        'sequence',
        'title',
        'process_template_field_type_id',
        'required',
        'hide_on_ticket',
        'active',
        'validation_rules' ],"canEdit":_vm.canEdit,"noBadge":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var data = ref.data;
      var childProps = ref.childProps;
      var childEvents = ref.childEvents;
return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('InlineEdit',_vm._g(_vm._b({attrs:{"label":_vm.titleLabel,"rules":_vm.rules.title,"autofocus":!!_vm.isNew}},'InlineEdit',childProps.title,false),childEvents.title))],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('InlineEdit',_vm._g(_vm._b({attrs:{"label":"Field Type","inputType":"select","items":_vm.processTemplateFieldTypesStore.items,"item-text":"title","item-value":"id","canEdit":_vm.canEdit && !_vm.isNew}},'InlineEdit',childProps.process_template_field_type_id,false),childEvents.process_template_field_type_id))],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('InlineEdit',_vm._g(_vm._b({staticClass:"mt-0",attrs:{"label":"Active","inputType":"checkbox","hide-details":"","canEdit":_vm.canEdit && !_vm.isNew && !_vm.operationDetailsCount}},'InlineEdit',childProps.active,false),childEvents.active))],1)],1),_c('ProcessDetailValidations',_vm._g(_vm._b({attrs:{"canEdit":_vm.canEdit && !_vm.isNew,"fieldTypeId":data.process_template_field_type_id}},'ProcessDetailValidations',childProps.validation_rules,false),childEvents.validation_rules))],1)]}}],null,false,3868418419)}):_vm._e(),(_vm.isNew)?_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(_vm.entity)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"no-rotate",attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1)]}}],null,false,1025500991)},[_c('span',[_vm._v("Delete Process Detail")])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }