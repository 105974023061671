<template>
  <v-card>
    <v-card-title>
      Process Details
      <v-spacer></v-spacer>
      <v-checkbox label="Only Active" v-model="onlyActive" class="pr-2" />
      <v-tooltip v-if="canEdit" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="addProcessDetail">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add Process Detail</span>
      </v-tooltip>
    </v-card-title>
    <draggable
      v-if="processDetailItems.length"
      tag="v-expansion-panels"
      :value="processDetailItems"
      :component-data="expansionData"
      v-bind="dragOptions"
      @input="updateOrder"
    >
      <ProcessDetailsListItem
        v-for="item in processDetailItems"
        :ref="getItemRef(item)"
        :key="getItemKey(item)"
        :entity="item"
        :canEdit="canEdit"
      />
    </draggable>
    <v-container v-else>
      <v-card-text>
        <p>No process details found.</p>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import { sortBy } from "lodash-es";
import draggable from "vuedraggable";
import { computed, ref, toRef, nextTick } from "vue-demi";
import { useFind } from "feathers-pinia";
import { useProcessTemplateFields } from "../store/processTemplateFields.pinia";
import ProcessDetailsListItem from "./ProcessDetailsListItem.vue";

export default {
  name: "ProcessDetailList",
  components: {
    draggable,
    ProcessDetailsListItem,
  },
  props: {
    processId: {
      type: String,
      default: () => null,
    },
    canEdit: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { root, refs }) {
    const processTemplateFieldsStore = useProcessTemplateFields();
    const processId = toRef(props, "processId");
    const canEdit = toRef(props, "canEdit");
    const openPanels = ref([]);
    const onlyActive = ref(true);

    // Process detail params
    const processDetailsParams = computed(() => {
      return {
        temps: true,
        query: {
          process_template_id: processId.value,
          $sort: { sequence: 1 }, // sort by sequence,
        },
      };
    });

    // Fetch process details
    const { items: processDetails } = useFind({
      model: processTemplateFieldsStore.Model,
      params: processDetailsParams,
    });

    const processDetailItems = computed(() => {
      return sortBy(
        processDetails?.value?.filter((i) =>
          onlyActive.value ? i.active : true
        ) ?? [],
        (i) => {
          if (i.sequence === 0)
            return (processDetails?.value?.length ?? 9999) + 1;
          return parseInt(i.sequence);
        }
      );
    });

    // options for draggable component
    const dragOptions = computed(() => {
      return {
        handle: ".drag-handle",
        disabled: !canEdit.value,
      };
    });

    // Get the item key
    const getItemKey = (item) => {
      return item.id ?? item.__tempId ?? 999;
    };
    // Get item ref
    const getItemRef = (item) => {
      return `process-detail-panel-${getItemKey(item)}`;
    };

    // Custom method to update the order
    const updateOrder = (items) => {
      // Starting sequence number
      let currentSequence = 1;
      // Loop through each item and update the sequence
      items.map((item) => {
        // Set directly to the entity so it updates instantly
        item.sequence = item.active ? currentSequence : 0; // Inactive gets sequence 0
        // Update the clone, so data the sequence won't get overwritten
        if (processTemplateFieldsStore.clonesById[item.id])
          processTemplateFieldsStore.clonesById[item.id].sequence =
            item.sequence;
        // Sync the new sequence with the API
        item.patch({ data: { sequence: item.sequence } });
        // Increment the sequence
        if (item.active) currentSequence++;
      });
    };

    const addProcessDetail = () => {
      // Get the next sequence
      const sequence =
        processDetailItems.value.reduce(
          (max, item) => Math.max(max, item.sequence),
          0
        ) + 1;
      // Create the new process detail
      const item = processTemplateFieldsStore.addProcessTemplateField(
        processId.value,
        sequence
      );
      // Wait for next tick
      nextTick(() => {
        // Find the new item index (always last, since indexes are being retained)
        const newItemIndex = processDetailItems.value.length - 1;
        // Open new item, and close others
        openPanels.value = [newItemIndex];
        // Scroll to the new item
        root.$vuetify.goTo(refs[getItemRef(item)][0]);
      });
    };

    const expansionPanelEvents = ref({
      change: (value) => {
        openPanels.value = value;
      },
      input: (value) => {
        openPanels.value = value;
      },
    });

    const expansionData = computed(() => ({
      on: expansionPanelEvents.value,
      props: {
        value: openPanels.value,
        accordion: true,
        multiple: true,
      },
    }));

    return {
      processDetails,
      openPanels,
      expansionData,
      dragOptions,
      onlyActive,
      processDetailItems,
      getItemKey,
      getItemRef,
      updateOrder,
      addProcessDetail,
    };
  },
};
</script>
