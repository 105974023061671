<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-row>
        <v-col class="d-flex align-center">
          <v-icon
            v-if="entity && entity.active && canEdit"
            class="drag-handle mr-2"
            color="grey"
          >
            mdi-cursor-move
          </v-icon>
          <span>
            {{ titleText }}
          </span>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-tooltip v-if="operationDetailsCount != null" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              color="success"
              class="align-self-center mr-1"
              small
            >
              {{ operationDetailsCount }}
            </v-chip>
          </template>
          <span>Operation Details Count</span>
        </v-tooltip>
        <v-icon>mdi-chevron-down</v-icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon :color="entity.active ? 'success' : 'error'" small>
                mdi-circle
              </v-icon>
            </v-btn>
          </template>
          <span>{{ entity.active ? "Active" : "Inactive" }}</span>
        </v-tooltip>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <InlineFieldsForm
        v-if="entity"
        :entity="entity"
        :entityStore="processTemplateFieldsStore"
        :fields="[
          'sequence',
          'title',
          'process_template_field_type_id',
          'required',
          'hide_on_ticket',
          'active',
          'validation_rules',
        ]"
        :canEdit="canEdit"
        noBadge
      >
        <template v-slot:default="{ data, childProps, childEvents }">
          <v-container>
            <v-row>
              <v-col cols="12" sm="8">
                <InlineEdit
                  v-bind="childProps.title"
                  v-on="childEvents.title"
                  :label="titleLabel"
                  :rules="rules.title"
                  :autofocus="!!isNew"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <InlineEdit
                  v-bind="childProps.process_template_field_type_id"
                  v-on="childEvents.process_template_field_type_id"
                  label="Field Type"
                  inputType="select"
                  :items="processTemplateFieldTypesStore.items"
                  item-text="title"
                  item-value="id"
                  :canEdit="canEdit && !isNew"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="4">
                <InlineEdit
                  v-bind="childProps.active"
                  v-on="childEvents.active"
                  label="Active"
                  inputType="checkbox"
                  hide-details
                  class="mt-0"
                  :canEdit="canEdit && !isNew && !operationDetailsCount"
                />
              </v-col>
            </v-row>
            <ProcessDetailValidations
              v-bind="childProps.validation_rules"
              v-on="childEvents.validation_rules"
              :canEdit="canEdit && !isNew"
              :fieldTypeId="data.process_template_field_type_id"
            />
          </v-container>
        </template>
      </InlineFieldsForm>
      <v-card-actions v-if="isNew">
        <v-spacer />
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="deleteItem(entity)">
              <v-icon color="red" class="no-rotate">mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Delete Process Detail</span>
        </v-tooltip>
      </v-card-actions>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { computed } from "vue-demi";
import { useProcessCount } from "../store/processCount.pinia";
import { useProcessTemplateFieldTypes } from "../store/processTemplateFieldTypes.pinia";
import { useProcessTemplateFields } from "../store/processTemplateFields.pinia";
import InlineFieldsForm from "./InlineFieldsForm.vue";
import InlineEdit from "./InlineEdit.vue";
import ProcessDetailValidations from "./ProcessDetailValidations.vue";
import { required, maxLength } from "../utils/rules";

export default {
  name: "ProcessDetailsListItem",
  components: {
    InlineFieldsForm,
    InlineEdit,
    ProcessDetailValidations,
  },
  props: {
    entity: {
      type: Object,
      default: () => ({}),
    },
    canEdit: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    const ProcessCountStore = useProcessCount();
    const processTemplateFieldTypesStore = useProcessTemplateFieldTypes();
    const processTemplateFieldsStore = useProcessTemplateFields();

    const titleLabel = computed(() => {
      return props.entity?.title?.length ? "Title" : "Title (Unsaved Item)";
    });

    const titleText = computed(() => {
      return props.entity?.title?.length
        ? props.entity?.title
        : titleLabel.value;
    });

    const isNew = computed(() => {
      return props.entity?.__tempId?.length ?? false;
    });

    const operationDetailsCount = computed(() => {
      if (!props.entity?.id) return null;
      const count = parseInt(
        ProcessCountStore.operationDetailCountById(
          props.entity?.id.toLowerCase()
        )
      );
      if (isNaN(count)) return null;
      return count;
    });

    const rules = computed(() => {
      return {
        title: [required, maxLength(15)],
      };
    });

    const deleteItem = (item) => {
      if (isNew.value) {
        // This doesn't exist in the database yet, so we can just remove it
        return processTemplateFieldsStore.removeFromStore(item);
      }
    };

    return {
      operationDetailsCount,
      processTemplateFieldTypesStore,
      processTemplateFieldsStore,
      titleLabel,
      titleText,
      isNew,
      rules,
      deleteItem,
    };
  },
};
</script>

<style>
.drag-handle {
  cursor: move;
}
</style>
